<template>
  <div class="flex flex-row items-center">
    <IconButton icon="arrow-left" @click="$router.back()" v-if="!hideBackButton" />
    <p class="p-2 text-primary text-sm font-medium flex items-center" v-html="title">

    </p>
    <slot name="top"></slot>
    <span class="flex-grow"></span>
    
    <div v-if="
      !!currentRoute.meta.topComponent &&
      currentRoute.meta.topComponentEvent &&
      hideTopComp == false
    ">
      <component :is="currentRoute.meta.topComponent" :contextData="{ ...contextData, item: contextData.item }"
        @tc_event="currentRoute.meta.topComponentEvent" />
    </div>
    <div class="relative" v-if="menuActions && menuActions.length > 0">
      <IconMenu :actions="menuActions" :contextData="{ ...contextData, item: contextData.item }" />
    </div>
  </div>
  <div class="bg-orange flex flex-row p-2 rounded shadow m-2 items-center"
    v-if="contextData.item && contextData.item.Cancelled === true">
    <font-awesome-icon icon="exclamation-circle" class="text-black"></font-awesome-icon>
    <p class="p-2 text-black">This item has been cancelled</p>
  </div>
  <div class="bg-primary-300 flex flex-row p-2 rounded shadow m-2 items-center"
    v-if="contextData.item && contextData.item.Transferred === true">
    <font-awesome-icon icon="info-circle" class="text-white"></font-awesome-icon>
    <p class="p-2 text-white">
      This item was transferred into the database. The old number is
      {{ contextData.item.TransferredNumber }}
    </p>
  </div>
  <div class="grid grid-cols-1 grid-gap-2 md:grid-cols-3">
    <div v-for="field in computedDataFields" :key="field.key" :class="field.class">
      <p class="p-2 text-primary font-medium text-sm" v-if="field.break === true">
        {{ field.label }}
      </p>
      <Value :label="field.label" :value="getValue(contextData.item, field)"
        :link="getLink(contextData.item, field.link)" :tooltip="field.tooltip" :grow="field.grow || false"
        v-if="!field.break && !field.actionButton && !field.component" />
      <ActionValue :label="field.label" :value="getValue(contextData.item, field)" :actionText="field.actionText"
        :actionClass="field.actionClass" :grow="field.grow || false" @action="
          field.action({
            ...contextData,
            fieldValue: getValue(contextData.item, field),
          })
          " :disabled="field.actionDisabled ? field.actionDisabled(contextData) : false
          " v-if="!field.break && field.actionButton && !field.component" />
      <component :is="field.component" :contextData="contextData" v-if="field.component" />
    </div>
  </div>
</template>
<script setup>
import {
  IconMenu,
  Value,
  Loading,
  IconButton,
  ActionValue,
} from "@comp/index.js";
import { computed } from "@vue/runtime-core";

import { get } from "lodash";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const route = useRoute();
const currentRoute = computed(() => route);
const store = useStore();

const props = defineProps({
  title: String,
  dataFields: Array,
  contextData: Object,
  menuActions: Object,
  hideBackButton: Boolean,
  hideTopComp: Boolean,
});

const getValue = (item, field) => {
  let v = field.compute
    ? field.compute(props.contextData)
    : get(item, field.key, item[field.key]);


  switch (field.type) {
    case "date":
      return new Date(v).toLocaleDateString("en-gb", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    case "bool":
      return v
        ? "<p class='font-bold text-green'>Yes</p>"
        : "<p class='font-bold text-red'>No</p>";
    case "currency":
      return `${field.currencyType || store.getters.currency}${parseFloat(v)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    default:
      return v;
  }
};

// compute the link in the Value components
const getLink = (item, link) => {
  if (!link) return null;
  else {
    let linkParts = link
      .split("/")
      .map((part) => {
        if (part.includes(":")) return get(item, part.replace(":", ""));
        else return part;
      })
      .join("/")
      .replace("//", "/");
    return `/${linkParts}?linkedFrom=${currentRoute.value.path}&linkedFromNumber=${item.Number}`;
  }
};

const computedDataFields = computed(() => {
  return (props.dataFields || []).filter((i) => {
    return i.show ? i.show(props.contextData) : true;
  });
});
</script>
