export const addPartNumber = ({ form }) => {
    form.setConfig({
        "title": "Add Part Number",
        "type": "push",
        "location": "Database/PartNumbers",
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Manufacturer",
            "name": "ManufacturerID",
            "type": "dropdown",
            "dataLocation": "Database/Manufacturers",
            "idKey": "ID",
            "labelKey": "Name"
        }, {
            "label": "Description",
            "name": "Description",
            "type": "text",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook({ dropdownData, liveInputData }) {
            let selected = dropdownData.ManufacturerID.find(i => i.id === liveInputData.ManufacturerID);
            return {
                "Manufacturer": selected.label
            };
        },
        async postSubmitHook({ data, db }) {
            console.log(data);
            
            db.set(`Database/Manufacturers/${data.ManufacturerID}/PartNumbers/${data.ID}`, true);
        }

    });
    form.getDropdownData();
    form.toggle();
}  

export const editPartNumber = ({ form, item }) => {
    form.setValues({
        "Number": item.Number
    })
    form.setConfig({
        "title": "Add Part Number",
        "type": "update",
        "location": `Database/PartNumbers/${item.ID}`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }]
    });
    form.toggle();
};

export const linkPartNumber = ({ form, item, meta, db }) => {
    form.setConfig({
        "title": "Link Part Number",
        "description": `Link a Part Number to ${item.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${item.ID}/PartNumbers`,
        "fields": [{
            "label": "Part Number",
            "name": "_pn",
            "type": "dropdown",
            "dataLocation": "Database/PartNumbers",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        async preSubmitHook({ data }) {
            return {
                [data._pn]: true
            };
        },
        async postSubmitHook({ data }) {
            db.set(`Database/PartNumbers/${data._pn}/${meta.ParentType}/${item.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};
