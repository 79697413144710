import { appConfig, pad } from "../../../utils";

export const addReport = ({ form, store }) => {
    form.setConfig({
        "title": "Add Audit Sub Report",
        "type": "push",
        "location": "Audits/SubReports",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}SUB-QAR-${pad(store.state.counts.auditor["sub-reports"].count)}`
            }
        }, {
            "label": "Procedure",
            "name": "ProcedureID",
            "type": "dropdown",
            "labelKey": "Title",
            "idKey": "ID",
            "dataLocation": "Audits/Procedures"
        }, {
            "label": "Cycle",
            "name": "Cycle",
            "type": "text"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "dropdown",
            "dropdownData": ["Scheduled Audit", "AD-HOC", "Audit Review"].map(i => ({ label: i, id: i }))
        }, {
            "label": "HOD",
            "name": "HOD",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }
            , {
                "label": "HISM",
            "name": "DAM",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }
        ],
        preSubmitHook({ selectedDropdowns }) { 
            return {
                "Number": `${appConfig.docPrefix}SUB-QAR-${pad(store.state.counts.auditor["sub-reports"].count)}`,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Completed": "N/A"
                },
                "Completed": false,
                "Procedure": selectedDropdowns.ProcedureID.meta.Title,
                "Comment": "Start Sub Report Here"
            };
        },
        postSubmitHook({ db, typeRef, data }) {
            db.update(`counts/auditor/sub-reports`, { count: store.state.counts.auditor["sub-reports"].count + 1});
            db.set(`Audits/Procedures/${data.ProcedureID}/SubReports/${typeRef.key}`, true)
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editReport = ({ form, pageItem }) => {
    form.setValues(pageItem);
    form.setConfig({
        "title": "Add Audit Sub Report",
        "type": "update",
        "location": `Audits/SubReports/${pageItem.ID}`,
        "fields": [{
            "label": "Cycle",
            "name": "Cycle",
            "type": "text"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "dropdown",
            "dropdownData": ["Sub Contractor", "Scheduled Audit", "AD-HOC", "Audit Review"].map(i => ({ label: i, id: i }))
        }, {
            "label": "HOD",
            "name": "HOD",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "HISM",
            "name": "DAM",
            "type": "dropdown",
            "labelKey": "name",
            "idKey": "name",
            "dataLocation": "users",
            dataFilter(user) {
                return user.main;
            }
        }, {
            "label": "Report",
            "name": "Comment",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const completeReport = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Complete Sub Report",
        "type": "update",
        "location": `Audits/SubReports/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Completed": true,
                "Date/Completed": (new Date()).toISOString()
            }
        }
    });
    form.toggle();
};

export const addFile = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "submitButtonLabel": "Add",
        "type": "push",
        "location": `Audits/SubReports/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "File": files[0][0].name
            };
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`Audits/SubReports/${pageItem.ID}/Files/${typeRef.key}`, file[0]);
            }
        }
    });
    form.toggle();
}; 

export const removeFile = ({ form, rowItem, pageItem }) => {
    form.setConfig({
        "title": "Remove File",
        "submitButtonLabel": "Remove",
        "submitButtonClass": "cancel",
        "type": "delete",
        "location": `Audits/SubReports/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.remove(`Audits/SubReports/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.File);
            }
        }
    });
    form.toggle();
};
