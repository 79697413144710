

<template>
    <div class="w-full flex flex-col">

        <div class="m-2 p-2 bg-white rounded shadow flex flex-col">
            <p class="p-2">{{ selected.id }}</p>
            <div class="flex flex-row" v-if="!loading">
                <Dropdown class="flex-grow" label="Regulation" :options="regulationOptions" :disable-branch-nodes="true"
                    @select="selectionChanged" :value="selected.regId" />
            </div>
            <p class="p-2">Amenedments</p>
            <div class="flex flex-row" v-if="!loading">

                <div class="p-2 m-2 shadow rounded cursor-pointer" v-for="(amendment, amendmentIndex) in selected.amendments" @click="amendmentSelectionChanged(amendmentIndex)">
                    <p :class="`font-bold ${amendmentIndex === selected.amendmentIndex ? 'text-primary underline decoration-2 underline-offset-8' : 'text-gray'}`" >{{ amendment }}</p>
                </div>
                <span class="flex-grow"></span>
                <button class="primary" @click="edit">Edit Amendment {{ selected.amendments[selected.amendmentIndex] }}</button>  
                <button class="primary" @click="addAmendment">Add New Amendment</button>  
            </div>
            <Value label="Amendment" :value="selectedText" :grow="true"/>
        </div>
        <DynamicForm ref="form" @submitted="formSubmitted" />
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, reactive, ref } from 'vue';
import { db } from '../../../../db';
import { useRoute } from 'vue-router';

import DynamicForm from '../../../../components/DynamicForm/DynamicForm.vue';

import {
    Dropdown,
    Value
} from "@comp/index.js"

import {
    get
} from 'lodash';

const store = useStore();
const route = useRoute();
const form = ref(null);
const loading = ref(true);
const selectedAmendment = ref("");

let contextData = computed(() => {
    return {
        form: form.value,
        store,
        router,
        db,
        selectedRegulation,
        selectedAmendment
    };
});

const selected = reactive({
    regId: null,
    amendmentIndex: 0,
    amendmentId: null,
    amendments: []
});

const regulationOptions = computed(() => {
    if (!store.state["Database/Regulations/CS25"]) return [];
    return Object.entries(store.state["Database/Regulations/CS25"]).map(([subpart, regs]) => {
        return {
            "label": subpart,
            "id": subpart,
            "children": Object.values(regs).map(reg => {
                return {
                    "label": reg.title,
                    "id": `${subpart}.${reg.ID}`,
                    "meta": {
                        "reg": reg
                    }
                };
            })
        };
    });
});


const selectionChanged = (d) => {
    setSelected(d.id);
};

const amendmentSelectionChanged = (i) => {
    let selectedReg = get(store.state["Database/Regulations/CS25"], selected.regId);
    selected.amendmentIndex = i;
    selected.amendmentId = Object.values(get(selectedReg, "amendments", {})).map(amendment => amendment.ID)[selected.amendmentIndex];
};

const setSelected = id => {
    selected.regId = id;

    let selectedReg = get(store.state["Database/Regulations/CS25"], id);

    selected.amendmentIndex = Object.values(get(selectedReg, "amendments", {})).length - 1;
    selected.amendments = Object.values(get(selectedReg, "amendments", {})).map(amendment => parseInt(amendment.amendment)).sort((a, b) => {
        return a - b;
    });

    selected.amendmentId = Object.values(get(selectedReg, "amendments", {})).map(amendment => amendment.ID)[selected.amendmentIndex];
};

const selectedText = computed(() => {
    let selectedReg = get(store.state["Database/Regulations/CS25"], selected.regId);
    let selectedText = get(selectedReg, `amendments.${selected.amendmentId}.text`, "");

    return selectedText;

    // split the text into paragraphs by paragraph markers. The paragraph marker are in the following format. (a) (b) (c) etc. thjen put each into a table row with two columns, first column will be the paragraph marker and the second column will be the text.
    // const paragraphs = selectedText.split(/\([a-z]\)/);
    
    // paragraphs.shift()
    
    // let ps = paragraphs.map((p, i) => {
    
    //     const iAsLetter = String.fromCharCode(97 + i);
    //     return {
    //         "marker": `( ${iAsLetter} )`,
    //         "text": p
    //     };
    // });

    // const htmlTable = `<table>${ps.map(p => {
    //     return `<tr><td>${p.marker}</td><td>${p.text}</td></tr>`;
    // }).join("")}</table>`;

    // return htmlTable;

});

const formSubmitted = (d) => {
    
};

const edit = () => {


    form.value.setValues({
        "text": selectedText.value
    });

    form.value.setConfig({
        "title": "Edit Amendment",
        "location": `Database/Regulations/CS25/${selected.regId.replace(".", "/")}/amendments/${selected.amendmentId}`,
        "type": "update",
        "fields": [
            {
                "type": "richtext",
                "label": "Text",
                "name": "text",
                "required": true,
                "divClass": "md:col-span-3"
            }
        ],
        postSubmitHook: async (d) => {
            await db.store("Database/Regulations/CS25", store);
            setSelected(selected.regId);
        }
    });

    form.value.toggle();
};

const addAmendment = async () => {

    form.value.setConfig({
        "title": "Add Amendment",
        "location": `Database/Regulations/CS25/${selected.regId.replace(".", "/")}/amendments`,
        "type": "push",
        "fields": [
            {
                "type": "number",
                "label": "Amendment",
                "name": "amendment",
                "required": true,
            },
            {
                "type": "richtext",
                "label": "Text",
                "name": "text",
                "required": true,
                "divClass": "md:col-span-3"
            }
        ],
        postSubmitHook: async (d) => {
            await db.store("Database/Regulations/CS25", store);
            setSelected(selected.regId);
        }
    });

    form.value.toggle();
};

onMounted(async () => {
    await db.store("Database/Regulations/CS25", store);


    setSelected(`${route.query.subpart}.${route.query.id}`);

    loading.value = false;
});
</script>
