export const table = ` <table class="w-full text-text border border-gray-200 bg-white ">
            <thead>
                <tr>
                    <th class="py-2 px-4 border-b">Likelihood / Consequence</th>
                    <th class="py-2 px-4 border-b">A - Negligible</th>
                    <th class="py-2 px-4 border-b">B - Minor</th>
                    <th class="py-2 px-4 border-b">C - Major</th>
                    <th class="py-2 px-4 border-b">D - Hazardous</th>
                    <th class="py-2 px-4 border-b">E - Catastrophic</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="py-2 px-4 border-b">5 - Occasional</td>
                    <td class="py-2 px-4 border-b text-orange">AMBER - Tolerable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                </tr>
                <tr>
                    <td class="py-2 px-4 border-b">4 - Frequent</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-orange">AMBER - Tolerable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                </tr>
                <tr>
                    <td class="py-2 px-4 border-b">3- Remote</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-orange">AMBER - Tolerable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-red-500">RED - Unacceptable risk levels</td>
                </tr>
                <tr>
                    <td class="py-2 px-4 border-b">2 - Improbable</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-orange">AMBER - Tolerable risk levels</td>
                    <td class="py-2 px-4 border-b text-orange">AMBER - Tolerable risk levels</td>
                </tr>
                <tr>
                    <td class="py-2 px-4 border-b">1 - Extremely Improbable</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                    <td class="py-2 px-4 border-b text-green-500">GREEN - Acceptable risk levels</td>
                </tr>
            </tbody>
        </table>`;

export const tooltip = `
GREEN = Acceptable risk levels<br>
    The consequence is so unlikely or not severe enough to be of concern; the risk is acceptable.
    However, consideration should be given to reducing the risk further.<br><br>
YELLOW = Tolerable risk levels<br>
    The severity or likelihood of occurrence is of concern; measures to mitigate the risk to as low as
    reasonably practicable (ALARP) should be sought. Where the risk is still in the review category
    after this action has been taken, it may be that the cost of actions required to reduce the risk
    further are prohibitive.<br><br>
RED = Unacceptable risk levels<br>
    The Likelihood and/or severity of the consequences is intolerable. The operation or activity should
    stop immediately or not take place. Major mitigation will be necessary to reduce severity if the
    risk actually occurs or reduces the likelihood of the risk occurring. Normally it is the likelihood
    of the occurrence that can be reduced rather than the severity.
     ${table}
`;
