import Settings from "./Settings.vue";
import categories from "./categories.js";

export default [{
    "path": "/SafetyManagement/Settings",
    "component": Settings,
    "name": "Safety Management Settings",
    "meta": {
        "app": "SafetyManagement",
        "name": "Safety Management  Settings",
        "sideNav": true,
        "sideNavSection": "Settings",
        title() {
            return "Safety Management Settings"
        },
        "dataFields": [],
        "menuActions": [],
        "attachedItems": [categories]
    }
}];
