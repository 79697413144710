import {
    cancelDocument,
    download,
    editDocument,
    revisionRollback,
    switchParent
} from "./actions";
import {
    completeEadt,
    eadtFields,
    editEadtData
} from "../EADTs/actions";
import {
    thirdPartyDocs,
    thirdPartyDraws,
    thirdPartyOther
} from "../Miscellaneous/attachable.js";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import { revisions as _revisions } from "../../standardAttachables";
import {
    appConfig
} from "../../../utils";
import conAt from "../Concessions/attachable?test";
import designAttachables from "../DesignChanges/attachable?test";
import documentsAttachable from "./attachable?test";
import doiAt from "../DOIs/attachable?test";
import dqnAt from "../DQNs/attachable?test";
import flightAttachables from "../FlightConditions/attachable?test";
import partNumbers from "../ApprovedPartNumbers/attachable";
import sbAt from "../ServiceBulletins/attachable?test";
import trepRegs from "./trepRegs";

// const hidden = type => ({ pageItem, store }) => {
//     const docType = store.state["Database/DocumentTypes"][pageItem.TypeID];
//     if (!docType) return true;
//     return docType[type] === true || docType[type] === "true";
// };

// conAt.show = hidden("Concessions");
// dqnAt.show = hidden("DQNs");
// doiAt.show = hidden("DOIs");
// sbAt.show = hidden("ServiceBulletins");

// documentsAttachable.show = hidden("Documents");
// designAttachables.show = hidden("DesignChanges");
// flightAttachables.show = hidden("FlightConditions");

const revisions = {
    ..._revisions,
    "headers": [
        ..._revisions.headers
    ],
    "actions": [{
        "label": "Rollback Revision",
        "action": revisionRollback,
        show({ pageItem }) {
            return [Object.keys(pageItem.Revisions).length > 1, "No previous revisions found"];
        }
    }],
    "rowActions": [{
        "label": "View Archived File",
        async action({ toast, pageItem, rowItem, db }) {
            try {
                let docNumber = pageItem.Number.replace(".", "_");
                let rev = rowItem.Revision;
                let url = await db.download(`Database/Documents/${pageItem.ID}/${docNumber}_${rev}.pdf`);
                window.open(url, "__blank")
            } catch (err) {
                toast.error("Archive file not found");
            }
        }
    }]
};

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Mod Type",
    "key": "Type"
}, {
    "label": "Date Created",
    "key": "Date.Created",
    "type": "date"
}, {
    "label": "Date Completed",
    "key": "Date.Completed",
    "type": "date"
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];

const menuActions = [
    {
        "label": "Edit Document",
        "action": editDocument
    },
    {
        "label": "Cancel Document",
        "class": "text-red",
        "action": cancelDocument
    },
    {
        "label": "Change Main Parent",
        "action": switchParent
    },
    {
        "label": "Download Document",
        "break": true,
        "action": download
    }
];

const eadtAttachable = {
    "label": "EADT Information",
    "key": "Panels",
    "field": true,
    "fields": eadtFields,
    "actions": [{
        "label": "Edit EADT Data",
        "action": editEadtData,
        "show": ({ pageItem }) => {
            return [!pageItem.Completed, "The EADT is set as completed for this revision. It must be revised to edit the data."];
        }
    }, {
        "label": "Complete EADT",
        "action": completeEadt,
        "show": ({ pageItem }) => {
            return [!pageItem.Completed, "EADT is already completed"];
        }
    }],
    show({ pageItem }) {
        return pageItem.Type === "EADT";
    }
};

const att = [{
    "path": "/Database/Documents",
    "component": { ...ItemListView },
    "name": "Documents",
    "meta": {
        "app": "Database",
        "name": "Documents",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Documents",
        "dataPath": "Database/Refs/Documents",
        "selectionRoute": "Database/Documents/{ID}"
    }
}, {
    "path": "/Database/Documents/:id",
    "component": ItemView,
    "name": "Document",
    "meta": {

        "app": "Database",
        "name": "Documents",
        "dataPath": "Database/Documents",
        "selectionRoute": "Database/Documents/{ID}",
        "storeData": ["Database/DocumentTypes", "Database/Regulations/CS25"],
        title(item) {
            return `Documents: ${item.Number}`
        },
        "attachedItems": {
            "Revisions": {
                "attachables": [revisions]
            },
            "Designs": {
                "attachables": [designAttachables, flightAttachables]
            },
            "Design Items": {
                "attachables": [documentsAttachable, conAt, dqnAt, doiAt, sbAt]
            },
            "Part Numbers": {
                "attachables": [partNumbers]
            },
            "Additional Information": {
                "attachables": [
                    eadtAttachable,
                    thirdPartyDocs,
                    thirdPartyDraws,
                    thirdPartyOther
                ]
            },
            "TREP Regulations": {
                "attachables": [trepRegs]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
            "ParentType": "Documents"
        },
        async getLinkedData({ db, pageItem }) {

            if (pageItem.Type === "EADT" && pageItem.PartNumberID) {

                const partNumber = await db.once(`Database/PartNumbers/${pageItem.PartNumberID}`);
                const manufacturer = await db.once(`Database/Manufacturers/${partNumber.ManufacturerID}`);
                return {
                    PartNumber: partNumber.Number,
                    Manufacturer: manufacturer.Name,
                    ManufacturerID: manufacturer.ID,
                    Name: partNumber.Description
                };
            } else {
                return {};
            }
        }
    }
}, {
    "path": "/Database/TechnicalPublications",
    "component": { ...ItemListView },
    "name": "Technical Publications",
    "meta": {
        "app": "Database",
        "name": "Technical Publications",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "tableSettings": "Documents",
        "dataPath": "Database/Refs/Documents",
        "selectionRoute": "Database/Documents/{ID}",
        filter(item) {
            return ["TEC", "AMMS", "WDMS", "IPCS"].includes(item.Number.split(`${appConfig.docPrefix}`)[1]?.split("-")[0])
        }
    }
}];

export default att;
