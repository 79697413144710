export default {
    "label": "Arrangement Types",
    "key": "ArrangementTypes",
    "location": "Database/ArrangementTypes/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }, {
        "headerName": "NameShort",
        "field": "NameShort"
    }, {
        "headerName": "Expiration in Months",
        "field": "Months"
    }],
    "actions": [{
        "label": "Add Type",
        "action": ({ form }) => {
            form.setConfig({
                "title": "Add Type",
                "type": "push",
                "location": "Database/ArrangementTypes/",
                "fields": [{
                    "label": "Name",
                    "name": "Name",
                    "type": "text"
                }, {
                    "label": "Short Name",
                    "name": "NameShort",
                    "type": "text"
                }, {
                    "label": "Expiration in Months",
                    "name": "Months",
                    "type": "number"
                }]
            });

            form.toggle();
        }
    }],
    "rowActions": [{
        "label": "Edit Type",
        "action": ({ form, rowItem }) => {
            form.setValues(rowItem);

            form.setConfig({
                "title": "Edit Type",
                "type": "update",
                "location": `Database/ArrangementTypes/${rowItem.ID}`,
                "fields": [{
                    "label": "Name",
                    "name": "Name",
                    "type": "text",
                    
                }, {
                    "label": "Short Name",
                    "name": "NameShort",
                    "type": "text",
                }, {
                    "label": "Expiration in Months",
                    "tooltip": "0 for no expiration.",
                    "step": "1",    
                    "name": "Months",
                    "type": "number",
                }]
            });

            form.toggle();
        }
    }]
}
