import {
} from "../../../utils.js";

export const addWorkInstruction = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add Work Instruction",
        "type": "push",
        "location": `Database/WorkInstructions`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text"
        }],
        preSubmitHook() {
            return {
                "Revision": 1,
                "RevisionID": "---R1",
                "Revisions": {
                    "---R1": {
                        "Revision": 1,
                        "ID": "---R1",
                        "Date": (new Date()).toISOString()
                    }
                }
            }
        },
        async postSubmitHook({ db, typeRef }) {
            await db.set(`Database/Handbooks/${pageItem.ID}/WorkInstructions/${typeRef.key}`, true);
        }
    });
    form.toggle();
};

export const editWorkInstruction = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Work Instruction",
        "type": "update",
        "location": `Database/WorkInstructions/${item.ID}`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text"
        }]
    });
    form.toggle();
};

export const deleteWorkInstruction = () => {
    form.setConfig({
        "title": "Remove WorkInstruction",
        "type": "delete",
        "description": "Are you sure you want to remove this WorkInstruction?",
        "location": `Database/Handbooks/${pageItem.ID}/WorkInstructions/${rowItem.ID}`,
        "fields": []
    });
    form.toggle();
};

export const reviseWorkInstruction = async ({ form, store, item, db }) => {
    // Calculate the next revision
    let nextRevision = parseInt(item.Revision) + 1;
    let nextRevisionID = `--R${nextRevision}`;

    const mfarQuestions = Object.values(await db.store("Database/MFARQuestions", store));

    const mfarFields = mfarQuestions.map((question) => {
        return {
            "label": question.Question,
            "name": `Revisions[${nextRevisionID}][MFAR][${question.ID}]`,
            "type": "dropdown",
            "dropdownData": [{
                "label": "Yes",
                "id": "Yes"
            }, {
                "label": "N/A",
                "id": "N/A"
            }]
        }
    });

    form.setConfig({
        "title": "Revise Handbook",
        "description": `Revise Handbook <strong class="text-primary">${item.Number}</strong> from Revision <strong class="text-primary">${item.Revision}</strong> to Revision <strong class="text-primary">${nextRevision}</strong>.<br />`,
        "location": `Database/WorkInstructions/${item.ID}`,
        "type": "update",
        "fields": [{
            "label": "File",
            "name": `Revisions[${nextRevisionID}][File]`,
            "type": "file"
        },
        ...mfarFields
        ],
        async preSubmitHook({ files }) {
            for (const file of files) {
                await db.upload(`Database/Handbooks/---hb03/Procedures/${item.ID}/Revisions/${nextRevisionID}`, file[0]); // uses the legacy folder structure
                await db.set(`Database/Handbooks/---hb03/WorkInstructions/${item.ID}/Revisions/${nextRevisionID}/File`, file[0].name);
            };
            return {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                [`Revisions/${nextRevisionID}`]: {
                    "Revision": nextRevision,
                    "By": store.state.user.Basic.name,
                    "ID": nextRevisionID,
                    "Date": (new Date()).toISOString(),
                    "File": files[0][0].name
                }
            };
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const deleteChangeRequest = ({ form, pageItem, rowItem }) => {
    form.setConfig({
        "title": "Remove Change Request",
        "desctipion": "Are you sure you want to delete this Change Request",
        "location": `Database/Handbooks/${pageItem.ID}/ChangeRequests/${rowItem.ID}`,
        "type": "delete",
        "fields": []
    });
    form.toggle();
};

export const addWorkInstructionNote = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add WorkInstruction Note",
        "location": `Database/Handbooks/${pageItem.ID}/WorkInstructionNotes`,
        "type": "push",
        "fields": [{
            "type": "dropdown",
            "name": "WorkInstructionID",
            "label": "WorkInstruction",
            "dropdownData": Object.values(pageItem.WorkInstructions).map(WorkInstruction => {
                return {
                    "label": WorkInstruction.Number,
                    "id": WorkInstruction.ID
                };
            }),
        }, {
            "type": "richtext",
            "name": "Note",
            "label": "WorkInstruction Note",
            "tooltip": "Add a note to the WorkInstruction",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook({ selectedDropdowns }) {
            return {
                "Revision": pageItem.Revision,
                "WorkInstruction": selectedDropdowns.WorkInstructionID.label,
                "Status": "Pending",
                "Date": (new Date()).toISOString(),
                "HandbookRevision": pageItem.Revision,
            };
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editWorkInstructionNote = ({ form, pageItem, rowItem }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Update WorkInstruction Note",
        "location": `Database/Handbooks/${pageItem.ID}/WorkInstructionNotes/${rowItem.ID}`,
        "type": "update",
        "fields": [{
            "type": "richtext",
            "name": "Note",
            "label": "WorkInstruction Note",
            "tooltip": "Add a note to the WorkInstruction",
            "divClass": "md:col-span-3"
        }, {
            "type": "dropdown",
            "name": "Status",
            "label": "Status",
            "dropdownData": [{
                "label": "Pending",
                "id": "Pending"
            }, {
                "label": "Implimented",
                "id": "Implimented"
            }, {
                "label": "Rejected",
                "id": "Rejected"
            }]
        }]
    });
    form.getDropdownData();
    form.toggle();
};


export const viewHandbookRevision = async ({ form, rowItem, db, store }) => {

    const mfarQuestions = Object.values(await db.store("Database/MFARQuestions", store));

    const mfarFields = mfarQuestions.map((question) => {
        return {
            "label": question.Question,
            "name": question.ID,
            "type": "static-value"
        };
    });

    const mfarValueNames = mfarQuestions.map((question) => {
        return question.ID;
    });

    form.setValues({
        "Date": rowItem.Date,
        "Reason": rowItem.Reason,
        "Number": rowItem.Number,
        ...(
            Object.fromEntries(
                mfarValueNames.map(
                    (name) => ([name, rowItem.MFAR[name]])
                )
            )
        )
    });

    form.setConfig({
        "title": `View Revision ${rowItem.Revision}`,
        "type": "view",
        "fields": [{
            "label": "Sign off Date",
            "name": "Date",
            "type": "static-value"
        }, {
            "label": "Reason",
            "name": "Reason",
            "type": "static-value",
            "divClass": "md:col-span-3"
        }, {
            "label": "MFAR Questions",
            "type": "sectionHeader",
            "divClass": "md:col-span-3"
        }, ...mfarFields]
    });

    form.toggle();

};
