import EngineerResponsibilities from "./EngineerResponsibilities/attachable";
import Regulation from "./Regulations/Regulation.vue";
import Settings from "./Settings.vue";
import TecSecResponsibilities from "./TecSecResponsibilities/attachable";
import aircraftCodes from "./AircraftCodes/attachable";
import customers from "./Customers/attachable";
import docTypes from "./DocumentTypes/attachable";
import documentRevisionReasons from "./DocumentRevisionReasons/attachable";
import drawingRevisionReasons from "./DrawingRevisionReasons/attachable";
import drawingTypes from "./DrawingTypes/attachable";
import flightConditionPurpose from "./FlightConditionPurposes/attachable";
import mfarQuestions from "./MFARQuestions/attachable";
import regs from "./Regulations/attachable";
import subDocs from "./SubDocumentTypes/attachable";
import suppliers from "./Suppliers/attachable";
import uploadStatuses from "./UploadStatuses/attachable";

export default [{
    "path": "/Database/Settings",
    "component": Settings,
    "name": "Database Settings",
    "meta": {
        "app": "Database",
        "name": "Database Settings",
        "sideNav": true,
        "sideNavSection": "Settings",
        title() {
            return "Database Settings"
        },
        "dataFields": [],
        "menuActions": [],
        "attachedItems": [aircraftCodes, docTypes, documentRevisionReasons, drawingTypes, drawingRevisionReasons, customers, suppliers, subDocs, flightConditionPurpose, EngineerResponsibilities, TecSecResponsibilities, mfarQuestions, regs, uploadStatuses]
    }
}, {
    "path": "/Database/Settings/Regulations",
    "component": Regulation,
    "name": "Regulation",
    
}];
