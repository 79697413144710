<template>
  <div class="fixed top-0 bottom-0 left-0 right-0 z-top z-50 pb-12"
    style="background: rgba(0, 0, 0, 0.3); overflow-y: auto" v-if="state.open">
    <div class="mx-auto container">
      <div class="bg-white rounded shadow p-2 border border-gray-100 opacity-100 mt-12">
        <div class="flex flex-row items-center">
          <p class="p-2 font-medium flex-grow text-primary">
            {{ dynamicForm.config.title }}
          </p>
          <IconButton icon="times" @click="toggle" class="cancel" />
        </div>
        <p :class="`p-2 text-text ${dynamicForm.config.descriptionStyles}`" v-if="dynamicForm.config.description"
          v-html="dynamicForm.config.description"></p>
        <Loading v-if="state.loading" />
        <Loading message="Submitting Data" v-if="state.submitting" />
        <div class="flex flex-col items-center" v-show="formHasSteps">
          <p class="p-2 text-primary font-medium text-md">
            Step {{ dynamicForm.stepToShow }} of {{ numberOfSteps }}
          </p>
        </div>
        <form ref="__form" v-if="!state.loading" v-show="!state.submitting" @submit="submit" autocomplete="off">
          <div class="grid grid-cols-1 md:grid-cols-3 grid-gap-3">
            <div v-for="(field, i) in fields" :key="field.name" :class="field.divClass">
              <p class="p-2 text-primary font-medium text-sm" v-if="field.type === 'sectionHeader'">
                {{ field.label }}
              </p>
              <hr v-if="field.break === true" />
              <Input v-bind="field" :label="field.label" :tooltip="field.tooltip" :name="field.name" :min="field.min"
                :max="field.max" :multiple="field.multiple" :type="field.type || 'text'" :accept="field.accept || ''"
                :step="field.step || '0.25'" :required="get(field, 'required', true)"
                :disabled="field.disabled === true" :value="dynamicForm.liveInputData[field.name]"
                v-model="dynamicForm.liveInputData[field.name]" v-if="field.type === 'text' ||
                  field.type === 'number' ||
                  field.type === 'file' ||
                  field.type === 'date'
                " :ref="(el) => (dynamicForm.inputElements[i] = el)" />

              <Checkbox v-bind="field" type="checkbox" :label="field.label" :name="field.name"
                :required="get(field, 'required', true)" :disabled="field.disabled === true"
                v-if="field.type === 'checkbox'" :ref="(el) => (dynamicForm.inputElements[i] = el)"
                :checked="dynamicForm.liveInputData[field.name] === 'true' || dynamicForm.liveInputData[field.name] === true" />

              <Value v-bind="field" :label="field.label" :value="field.value({ ...dynamicForm }, store)"
                :tooltip="field.tooltip" v-model="dynamicForm.liveInputData[field.name]" v-if="field.type === 'value'"
                :ref="(el) => (dynamicForm.inputElements[i] = el)" :grow="field.grow || false" :name="field.name" />

              <Value v-bind="field" :label="field.label" :value="dynamicForm.liveInputData[field.name]"
                v-if="field.type === 'static-value'" :ref="(el) => (dynamicForm.inputElements[i] = el)"
                :grow="field.grow || false" :name="field.name" />

              <Dropdown v-bind="field" :label="field.label" :name="field.name"
                :required="field.required === false ? false : true" :disabled="field.disabled === true"
                :value="dynamicForm.liveInputData[field.name]" v-model="dynamicForm.liveInputData[field.name]"
                :options="dynamicForm.dropdownData[field.name]"
                :disable-branch-nodes="field.disableBranchNodes || false" :multiple="field.multiple"
                v-if="field.type === 'dropdown'" :ref="(el) => (dynamicForm.inputElements[i] = el)"
                :search="field.search || false" :has-other="field.hasOther || false"
                :showSelectAll="field.showSelectAll || false" />

              <ToggleWithInput v-bind="field" :label="field.label" :name="field.name" :inputName="field.inputName"
                :required="get(field, 'required', true)" :disabled="field.disabled === true"
                :value="dynamicForm.liveInputData[field.name]" v-model="dynamicForm.liveInputData[field.name]"
                :inputValue="dynamicForm.liveInputData[field.inputName]" v-if="field.type === 'confirmationInput'"
                :ref="(el) => (dynamicForm.inputElements[i] = el)" />

              <Richtext v-bind="field" :label="field.label" :name="field.name" :required="get(field, 'required', true)"
                :disabled="field.disabled === true" :minimised="field.minimised === true"
                :value="dynamicForm.liveInputData[field.name]" v-model="dynamicForm.liveInputData[field.name]"
                v-if="field.type === 'richtext'" :ref="(el) => (dynamicForm.inputElements[i] = el)" />

              <Toggle v-bind="field" :label="field.label" :name="field.name"
                :value="dynamicForm.liveInputData[field.name]" v-model="dynamicForm.liveInputData[field.name]"
                v-if="field.type === 'toggle'" :ref="(el) => (dynamicForm.inputElements[i] = el)" />
            </div>
          </div>
          <div class="flex flex-row items-center">

            <button type="button" class="" v-if="dynamicForm.stepToShow > 1"
              @click="dynamicForm.stepToShow--">Back</button>
            <button :class="dynamicForm.config.submitButtonClass || 'success'">
              {{
              dynamicForm.config.submitButtonLabel || (formHasSteps ? "Next" : "Save")
              }}
            </button>
            <div v-for="(button, index) in dynamicForm.config.additionalButtons" :key="`-ad-but-${index}`">
              <button type="button" :class="button.class" @click="button.action">
                {{ button.label }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  onMounted,
  reactive,
  defineExpose,
  watch,
  getCurrentInstance,
  computed,
} from "vue";
import {
  IconButton,
  Input,
  Value,
  Dropdown,
  Loading,
  ConfirmationInput,
  Checkbox,
  Richtext,
  Toggle,
  ToggleWithInput,
} from "@comp";
import { get, merge, sortBy, set } from "lodash";
import serialize from "form-serialize";
import { db } from "../../db.js";
import { flattenObject } from "../../utils.js";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
const store = useStore();
const toast = useToast();

const instance = getCurrentInstance();

const __form = ref(null);

let state = reactive({
  open: false,
  loading: false,
  submitting: false,
});

let dynamicForm = reactive({
  config: {
    title: "Standard Config",
  },
  item: {},
  dropdownData: {},
  liveInputData: {},
  inputElements: [],
  stepToShow: 1,
  stepData: {}
});

const emit = defineEmits(["submitted"]);

let fields = computed(() => {
  if (!dynamicForm.config.fields) return [];
  return dynamicForm.config.fields.filter((field) => {
    const canShowField = field.show ? field.show(dynamicForm) : true;
    const fieldInStep =
      formHasSteps.value === true ? field.formStep === dynamicForm.stepToShow : true;
    return canShowField && fieldInStep;
  });
});

const formHasSteps = computed(() => {
  return dynamicForm.config.isSteppedForm === true && dynamicForm.config.fields.some((field) => field.formStep);
});

const numberOfSteps = computed(() => {
  if (!dynamicForm.config.fields) return 0;
  return Math.max(...dynamicForm.config.fields.map((field) => field.formStep));
});

let drowdownOptions = reactive({});

const toggle = () => {
  state.open = !state.open;

  if (state.open === false) {
    reset();
  }
};

const reset = () => {
  dynamicForm.config = {
    title: "Standard Config",
  };
  dynamicForm.item = {};
  dynamicForm.dropdownData = {};
  dynamicForm.liveInputData = {};
  dynamicForm.inputElements = [];
};

const setConfig = (newConfig) => {
  dynamicForm.config = newConfig;
};

const setValues = (values) => {
  dynamicForm.liveInputData = values;
};

const setValue = (name, value) => {
  dynamicForm.liveInputData[name] = value;
};

const setItem = (newItem) => {
  dynamicForm.item = newItem;
};


watch(() => dynamicForm.liveInputData, (to, from) => {

  if (dynamicForm.config.onFormChanged) {
    dynamicForm.config.onFormChanged(to, from);
  }
}, {
  deep: true
})

const submit = async (e) => {
  try {
    if (dynamicForm.config.type === "view") {
      toggle();
      return;
    }

    state.submitting = true;
    e.preventDefault();

    var _newData = serialize(e.srcElement, { hash: true });

    // check for files
    let files = fields.value
      .filter((f) => f.type === "file")
      .map((f) => {
        let _inputs = document.getElementsByName(f.name);
        let files = [];
        _inputs.forEach((input) => {
          if (input.files) files.push(input.files);
        });
        return files;
      })
      .flat();

    let data = merge({}, _newData, dynamicForm.stepToShowData);


    if (
      numberOfSteps.value > 0 &&
      dynamicForm.stepToShow < numberOfSteps.value
    ) {
      dynamicForm.stepToShowData = merge({}, _newData, dynamicForm.stepToShowData);
      if (dynamicForm.config.onStepChange) await dynamicForm.config.onStepChange({
        ...dynamicForm,
        data,
        files,
        db,
      });
      dynamicForm.stepToShow++;
      state.submitting = false;
      return;
    }




    let selectedDropdowns = {};

    // check for richtext and dropdowns
    fields.value.forEach((field, i) => {
      // Check if the field is a dropdown
      if (field.type === "dropdown") {
        // Find the selected dropdown item in dynamicForm.dropdownData
        selectedDropdowns[field.name] = dynamicForm.dropdownData[
          field.name
        ].find((item) => item.id === get(data, field.name));
      }

      // Check if the field is a toggle or confirmationInput
      if (field.type === "toggle" || field.type === "confirmationInput") {
        // Replace square brackets in the field name with dots for use with the get and set functions
        let fieldName = field.name.replace(/\[/g, ".").replace(/\]/g, "");
        // Set the value of the field in the data object based on the value in the form
        set(data, fieldName, get(data, fieldName, false) === "true");
      }
    });

    const preSubmitHook = dynamicForm.config.preSubmitHook
      ? await dynamicForm.config.preSubmitHook({
        ...dynamicForm,
        data,
        files,
        db,
        selectedDropdowns,
      })
      : {};

    var typeRef;

    let newData = Object.assign(data, preSubmitHook);

    let pushMultiKeys = [];
    if (dynamicForm.config.type === "pushMulti") {
      // Split the value of pushMultiKey into an array
      let split = newData[dynamicForm.config.pushMultiKey].split(",");

      // For each split value, push a new object to the database
      for (let splitItem of split) {
        typeRef = await db.push(dynamicForm.config.location, {
          ...newData,
          [dynamicForm.config.pushMultiKey]: splitItem,
        });
        pushMultiKeys.push(typeRef.key);
      }
    } else if (dynamicForm.config.type !== "ignoreAutoAction") {
      // If the type is not "ignoreAutoAction", perform the appropriate database action
      typeRef = await db[dynamicForm.config.type](
        dynamicForm.config.location,
        // If the type is "push" or set is set to true, use newData as is.
        // Otherwise, flatten the object first.
        dynamicForm.config.type === "push" || dynamicForm.config.set === true
          ? newData
          : flattenObject(newData)
      );
    }

    newData.ID = typeRef?.key || data.ID;

    if (dynamicForm.config.postSubmitHook)
      await dynamicForm.config.postSubmitHook({
        ...dynamicForm,
        typeRef,
        data,
        newData,
        files,
        db,
        pushMultiKeys,
        selectedDropdowns
      });
    dynamicForm.stepToShow
    state.submitting = false;
    dynamicForm.stepToShow = 1;
    dynamicForm.stepToShowData = {};
    toast.success(dynamicForm.config.successMessage || "Form submitted");
    toggle();

    emit("submitted", dynamicForm);
  } catch (err) {
    state.submitting = false;
    toggle();
    console.error(err);
    toast.error(
      err.message || dynamicForm.config.errorMessage || "That form did not submit correctly."
    );
  }
};

async function getDropdownData() {
  let dropdownFields = dynamicForm?.config?.fields.filter(
    (i) => i.type === "dropdown"
  );
  state.loading = true;
  for (let field of dropdownFields) {
    if (field.dataLocation) {
      // IF run a query on that location
      // ELSE no subset of the data is needed then get all keys under that location
      let d = {};
      if (field.subset) {
        d = await db.query(
          field.dataLocation,
          field.subset.key,
          field.subset.value,
          store
        );
      } else if (field.dataKeys) {
        d = await db.keys(field.dataLocation, field.dataKeys);
      } else {
        d = await db.once(field.dataLocation);
      }
      //if nothing was found make dropdown data a empty array
      if (!d) {
        dynamicForm.dropdownData[field.name] = [];
        continue;
      }
      dynamicForm.dropdownData[field.name] = sortBy(
        Object.values(d)
          .filter((i) => {
            return field.dataFilter ? field.dataFilter(i) : true;
          })
          .map((i) => {
            if (field.map) i = field.map(i);
            return {
              id: i[field.idKey || "id"],
              label: i[field.labelKey || "label"],
              meta: i,
            };
          }),
        (i) => {
          return get(i, field.sortKey || "label");
        }
      );
    } else if (field.dropdownData) {
      dynamicForm.dropdownData[field.name] = field.dropdownData;
    }
  }
  state.loading = false;
  return;
}

function autoSetValues() {
  let values = {};
  dynamicForm?.config?.fields.forEach((field) => {
    values[field.name] = dynamicForm.item[field.name];
  });
  setValues(values);
  return values;
}

defineExpose({
  toggle,
  setConfig,
  setValues,
  setValue,
  setItem,
  getDropdownData,
  autoSetValues,
});
</script>
