 function addCategory({ form }) {
    form.setConfig({
        "title": "Add Category",
        "type": "push",
        "location": "SafetyManagement/Categories/",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
};

 function editCategory({ form, rowItem }) {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Edit Category",
        "type": "update",
        "location": `SafetyManagement/Categories/${rowItem.ID}`,
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
};

export default {
    "label": "Categorys",
    "location": "SafetyManagement/Categories/",
    "actions": [{
        "label": "Add Category",
        "action": addCategory
    }],
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "rowActions": [{
        "label": "Edit Category",
        "action": editCategory
    }]
};
