import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import conAttachable from "../Concessions/attachable";
import dcAttachable from "../DesignChanges/attachable";
import doiAttachable from "../DOIs/attachable";
import dqnAttachable from "../DQNs/attachable";
import {
    editEffectivity
} from "./actions";
import sbAttachable from "../ServiceBulletins/attachable";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}];

const menuActions = [
    {
        "label": "Edit Effectivity",
        "action": editEffectivity
    }
];

export default [{
    "path": "/Database/Effectivities",
    "component": {...ItemListView},
    "name": "Effectivities",
    "meta": {
        "app": "Database",
        "name": "Effectivities",
        "sideNav": true,
        "sideNavSection": "Misc",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Effectivities",
        "dataPath": "Database/Effectivities",
        "selectionRoute": "Database/Effectivities/{ID}"
    }
}, {
    "path": "/Database/Effectivities/:id",
    "component": ItemView,
    "name": "Effectivity",
    "meta": {
        "app": "Database",
        "name": "Effectivities",
        "dataPath": "Database/Effectivities",
        "selectionRoute": "Database/Effectivities/{ID}",
        title(item) {
            return `Effectivities: ${item.Number}`
        },
        "attachedItems": {
            "Items": {
                "attachables": [dcAttachable, dqnAttachable, conAttachable, doiAttachable, sbAttachable],
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
        "ParentType": "Effectivities",
        }
    }
}];
