import {
    accept,
    addGreenCard,
    adjustNonConDueDate,
    editNonConSeverityAndType
} from "./actions.js";
import {
    correctiveAction,
    extension,
    issue,
    preventativeAction,
    rootCause
} from "./internalAttachables.js";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import _jobCardsAttachable from "../../Database/JobCards/attachable.js";

const jobCardsAttachable = {
    label: _jobCardsAttachable.label,
    key: _jobCardsAttachable.key,
    location: _jobCardsAttachable.location,
    headers: _jobCardsAttachable.headers,
    rowActions: _jobCardsAttachable.rowActions
};

jobCardsAttachable.actions = [{
    "label": "Add Job Card",
    "action": async ({ store, db, pageItem, data }) => {
        await addGreenCard({
            store,
            db,
            pageItem: {
                "ID": pageItem.ParentID,
                "Number": pageItem.Number
            },
            data: pageItem,
            'NonConID': pageItem.ID
        });
    },
    show({ pageItem }) {
        return [!pageItem.JobCards, "A green card has already been added."]
    }
}];

const accepted = bool => bool === true ? "<span class='font-bold text-green'>Accepted</span>" : "<span class='font-bold text-red'>Not Yet Reviewed</span>";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Report",
    "key": "Report",
    "link": "Audits/Reports/:ReportID"
}, {
    "label": "Procedure",
    "key": "Procedure"
}, {
    "label": "Type and Severity",
    compute({ item }) {
        return `${item.Type} - ${item.Severity}`
    }
}, {
    "label": "Date Due",
    "key": "Date.Due",
    "type": "date"
}, {
    "label": "HoD Accepted Issue",
    compute({ item }) {
        return accepted(item.Issue.HOD)
    }
}, {
    "label": "Root Cause",
    compute({ item }) {
        return `HISM: ${accepted(item.RootCause.DAM)}<br>HoD: ${accepted(item.RootCause.HOD)}`
    },
    "grow": true
}, {
    "label": "Corrective Action",
    compute({ item }) {
        return `HISM: ${accepted(item.CorrectiveAction.DAM)}<br>HoD: ${accepted(item.CorrectiveAction.HOD)}`
    },
    "grow": true
}, {
    "label": "Preventative Action",
    compute({ item }) {
        return `HISM: ${accepted(item.PreventativeAction.DAM)}<br>HoD: ${accepted(item.PreventativeAction.HOD)}`
    },
    "grow": true
}];

const menuActions = [{
    "label": "Change Non Conformity Severity and Type",
    "action": editNonConSeverityAndType,
    // show({ pageItem }) {
    //     return [!pageItem.Issue.HOD, "HoD has already accepted this issue."]
    // }
}, {
    "label": "Adjust Non Conformity Due Date",
    "action": adjustNonConDueDate,
    // show({ pageItem }) {
    //     return [!pageItem.Issue.HOD, "HoD has already accepted this issue."]
    // }
}, {
    "label": "HoD Accept Issue",
    "action": accept("HOD", "Issue"),
    show({ pageItem }) {
        return [!pageItem.Issue.HOD, "HoD has already accepted this issue."]
    }
}];

export default [{
    "path": "/Audits/NonConformities",
    "component": { ...ItemListView },
    "name": "Non Conformities",
    "meta": {
        "app": "Audits",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],

        "name": "Non Conformities",
        "sideNav": true,
        "sideNavSection": "Reports",
        "appSwitcher": false,
        "icon": "check-circle",
        "tableSettings": "NonConformities",
        "dataPath": "Audits/NonConformities",
        "selectionRoute": "Audits/NonConformities/{ID}"
    }
}, {
    "path": "/Audits/NonConformities/:id",
    "component": ItemView,
    "name": "Non Conformity",
    "meta": {
        "app": "Audits",
        "storeData": ["Audits/Procedures", "Audits/Cycles"],
        "ParentType": "NonConformities",
        "name": "Non Conformity",
        title(item) {
            return `Non Conformity: ${item?.Number}`
        },
        "formMetadata": {
            "ParentType": "NonConformities",
        },
        "attachedItems": {
            "Issue": {
                "attachables": [issue]
            },
            "Actions Taken": {
                "attachables": [rootCause, correctiveAction, preventativeAction]
            },
            "Extension": {
                "attachables": [extension]
            },
            "Projects": {
                "attachables": [jobCardsAttachable]
            }
        },
        dataFields,
        menuActions
    }
}];
