import { addSafetyManagementForm, editSafetyManagementForm } from "./actions";
import {
    table as overallRiskTable,
    tooltip as overallRiskTooltip
} from "./overallRiskTooltip.js";

import ItemListView from "../../components/ItemListView/ItemListView.vue";
import ItemView from "../../components/ItemView/ItemView.vue";
import attachables from "./attachables.js";
import {
    getRiskColor
} from "./actions";
import settings from "./Settings/routes.js";

export default [{
    "path": "/SafetyManagement/Forms",
    "component": ItemListView,
    "name": "Safety Management Forms",
    "meta": {
        "app": "SafetyManagement",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 7,
        "sideNavPosition": 7,
        "sideNavSection": "Safety Management",
        "icon": "helmet-safety",
        "name": "Safety Management Forms",
        "tableSettings": "SafetyManagementForms",
        "dataPath": "SafetyManagement/Forms",
        "storeData": ["Database/DocumentTypes", "SafetyManagement/Categories"],
        "selectionRoute": "SafetyManagement/Forms/{ID}",
        "security": "General.SafetyManagementUser",
        "menuActions": [{
            "label": "Add Safety Management Form",
            "action": addSafetyManagementForm
        }]
    }
}, {
    "path": "/SafetyManagement/Forms/:id",
    "component": ItemView,
    "name": "Safety Management Form",
    "meta": {
        "app": "SafetyManagement",
        "name": "Safety Management Form",
        "dataPath": "SafetyManagement/Forms",
        "storeData": ["Database/DocumentTypes", "SafetyManagement/Categories"],
        "title": (item) => `Safety Management Form: ${item.Number}`,
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "md:col-span-2"
        }, {
            "label": "Category",
            "key": "Category"
        }, {
            "label": "Date Added",
            "key": "Date.Created",
            "type": "date"
        }, {
            "label": "Date Reviewed",
            "key": "Date.Reviewed",
            "type": "date"
        }, {
            "label": "Agreed Likelihood",
            "key": "SafetyManagerLikelihood"
        }, {
            "label": "Agreed Consequence",
            "key": "SafetyManagerConsequence"
        }, {
            "label": "Overall Risk",
            "tooltip": overallRiskTooltip,
            compute( { pageItem }) { 
                return getRiskColor(pageItem.SafetyManagerLikelihood.split(" - ")[0], pageItem.SafetyManagerConsequence.split(" - ")[0]);
            }
        }, {
            "label": "Risk Table",
            compute({ pageItem }) {
                return overallRiskTable;
            },
            "class": "md:col-span-3",
            "grow": true
        }],
        "menuActions": [{
            "label": "Edit Safety Management Form",
            "action": editSafetyManagementForm
        }],
        "attachedItems": {
            "Event Details": {
                "attachables": attachables
            }
        }
    }
}, ...settings]
