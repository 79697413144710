
export const addEffectivity = ({ form, pageItem, attachedItems, meta, store, db }) => {

    form.setConfig({
        "title": "Add Effectivity (commas separate to add multiple)",
        "type": "pushMulti",
        "pushMultiKey": "Number",
        "location": "Database/Effectivities",
        "description": `Add an effectivity to ${pageItem.Number}`,
        "fields": [{
            "label": "Effectivity / MSN Number",
            "name": "Number",
            "type": "text"
        }],
        async preSubmitHook({ data }) {

            let foundEffectivity = await db.query("Database/Effectivities", "Number", data.Number, store);

            if (foundEffectivity) {
                Object.keys(foundEffectivity).forEach(key => {
                    db.set(`Database/${meta.ParentType}/${pageItem.ID}/Effectivities/${key}`, true);
                    db.set(`Database/Effectivities/${key}/${meta.ParentType}/${pageItem.ID}`, true);
                });
                throw new Error(`Effectivity ${data.Number} already exists. A link has been created instead.`);
            }

            let ai = attachedItems.Effectivities.items.map(i => store.state[i]?.Number);

            if (ai.includes(data.Number)) {
                throw new Error(`Effectivity ${data.Number} already exists`);
            }

            return {
                "Date": {
                    "Created": new Date().toISOString()
                },
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "ParentTitle": pageItem.Title
            };
        },
        async postSubmitHook({ db, typeRef, pushMultiKeys }) {
            pushMultiKeys.forEach(async (key) => {
                await db.set(`Database/${meta.ParentType}/${pageItem.ID}/Effectivities/${key}`, true);
                await db.set(`Database/Effectivities/${key}/${meta.ParentType}/${pageItem.ID}`, true);
            });
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const editEffectivity = ({ form, item, store, attachedItems }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Edit Effectivity",
        "type": "update",
        "location": `Database/Effectivities/${item.ID}`,
        "fields": [{
            "label": "Effectivity / MSN Number",
            "name": "Number",
            "type": "text"
        }]
    });
    form.getDropdownData();
    form.toggle();
}

export const removeEffectivity = ({ form, pageItem, rowItem, meta }) => {
    form.setConfig({
        "title": "Remove Effectivity",
        "description": `Remove ${rowItem.Number} from ${pageItem.Number}`,
        "type": "delete",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Effectivities/${rowItem.ID}`
    });
    form.toggle();
};
