const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

// Children Imports
import ApprovedPartNumbers from "./ApprovedPartNumbers/routes.js";
import Arrangements from "./Arrangements/routes.js";
import BoxRequests from "./BoxRequests/routes.js";
import Commercials from "./Commercials/routes.js";
import Concessions from "./Concessions/routes.js";
import DOIs from "./DOIs/routes.js";
import DQNs from "./DQNs/routes.js";
import DatabaseSettings from "./Settings/routes.js";
import DesignChanges from "./DesignChanges/routes.js";
import Documents from "./Documents/routes.js";
import Drawings from "./Drawings/routes.js";
import EADTs from "./EADTs/routes.js";
import EWISs from "./EWIS/routes.js";
import Effectivities from "./Effectivities/routes.js";
import FlightConditionApprovalForms from "./FlightConditionApprovalForms/routes.js"
import FlightConditions from "./FlightConditions/routes.js";
import JobCards from "./JobCards/routes.js";
import MORs from "./MOR/routes.js";
import Manufacturers from "./Manufacturers/routes.js";
import Miscellaneous from "./Miscellaneous/routes.js";
import Occurrences from "./Occurrences/routes.js";
import PurchaseOrders from "./PurchaseOrders/routes.js";
import Repairs from "./Repairs/routes.js";
import SADDs from "./SADDs/routes.js";
import ServiceBulletins from "./ServiceBulletins/routes.js";
import ServiceOrders from "./ServiceOrders/routes.js";
import VariationOrders from "./VariationOrders/routes.js";

const shared = [
    ...DesignChanges,
    ...FlightConditions,
    ...Repairs,
    ...Documents,
    ...Drawings,
    ...Concessions,
    ...DQNs,
    ...DOIs,
    ...ServiceBulletins,
    ...Commercials,
    ...JobCards,
    ...PurchaseOrders,
    ...VariationOrders,
    ...FlightConditionApprovalForms,
    ...Arrangements,
    ...Effectivities,
    ...Occurrences,
    ...MORs,
    ...ApprovedPartNumbers,
    ...Manufacturers,
    ...DatabaseSettings,
    ...SADDs,
    ...EWISs,
    ...Miscellaneous,
    ...BoxRequests,
    ...EADTs
];

const stc = [];

const doa = [...ServiceOrders];


export default [...shared, ...(appConfig.appPrefix === 'doa' ? doa : stc)];
