<template>
  <div :class="`flex flex-row items-center ${!show ? 'pointer-events-none' : ''}`" v-show="hidden !== true">
    <p :class="labelClass" v-html="label" @click="$emit('change')"></p>
    <font-awesome-icon v-show="lock === true" icon="lock" class="text-gray mr-4"
      v-tooltip="{ content: lockText, html: true }"></font-awesome-icon>
    <font-awesome-icon v-show="show === true && warn === true" icon="exclamation-circle" class="text-orange mr-4"
      v-tooltip="{ content: warnText, html: true }"></font-awesome-icon>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  label: String,
  warn: Boolean,
  show: Boolean,
  selected: Boolean,
  warnText: String,
  lock: Boolean,
  lockText: String,
  hidden: Boolean
});

const labelClass = computed(() => {
  const selected = props.selected ? "text-primary font-medium" : "";
  const show = props.show === false || props.lock === true ? "pointer-events-none text-gray-400" : "";
  return `cursor-pointer flex-grow hover:text-primary hover:font-medium transition py-1 pl-3 text-sm ${selected} ${show}`;
});
</script>
4
