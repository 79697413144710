import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import {
    addManufacturer
} from "./actions.js"
import partNumbers from "../ApprovedPartNumbers/attachable.js"

const dataFields = [{
    "label": "Name",
    "key": "Name",
    "class": "md:col-span-3"
}];

export default [{
    "path": "/Database/Manufacturers",
    "component": {
        ...ItemListView
    },
    "name": "Manufacturers",
    "meta": {
        "app": "Database",
        "name": "Manufacturers",
        "sideNavSection": "Misc",
        "sideNav": true,
        "icon": "database",
        "tableSettings": "Manufacturers",
        "dataPath": "Database/Manufacturers",
        "selectionRoute": "Database/Manufacturers/{ID}",
        "menuActions": [{
            "label": "Add Manufacturer",
            "action": addManufacturer
        }],
        filter(item) {
            return item.Cancelled !== true
        }
    }
}, {
    "path": "/Database/Manufacturers/:id",
    "component": ItemView,
    "name": "Manufacturer",
    "meta": {
        "app": "Database",
        "name": "Manufacturers",
        "dataPath": "Database/Manufacturers",
        "selectionRoute": "Database/Manufacturers/{ID}",
        title(item) {
            return "Manufacturer"
        },
        "attachedItems": {
            "Links": {
                "attachables": [partNumbers]
            }
        },
        "dataFields": dataFields
    }
}];
