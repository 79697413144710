import {
    addBug,
    completeBug,
    editBug
} from "./actions";
import { disableUser, setDesignChangeFilter, updateUser } from "./UserManager/actions";

import ItemListView from "../../components/ItemListView/ItemListView.vue";
import ItemView from "../../components/ItemView/ItemView.vue";
import { addCustomer } from "./CustomerManager/actions";
import { addUser } from "./UserManager/actions";
import changeRequests from "./ChangeRequests/routes";
import forms from "./Forms/routes";
import { get } from "lodash";
import handbooks from "./Handbooks/routes";
import profile from "./Profile/routes";
import security from "./UserManager/attachable";
import staffTraining from "./StaffTraining/routes";

// Main Home View
export default [{
    "path": "/",
    "component": () => import("./Home.vue"),
    "name": "Overview",
    "meta": {
        "app": "Home",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 0,
        "sideNavPosition": 0,
        "sideNavSection": "Overview",
        "icon": "home",
        "name": "Overview",
        "storeData": ["business/leave/events"]
    }
}, ...profile, ...staffTraining, {
    "path": "/Userdata",
    "component": { ...ItemListView },
    "name": "User Manager",
    "meta": {
        "app": "Home",
        "sideNav": true,
        "name": "User Manager",
        "sideNav": true,
        "sideNavPosition": 0,
        "sideNavSection": "App Admin",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "UserManager",
        "dataPath": "users",
        "selectionRoute": "Userdata/{key}",

        "menuActions": [],
        lock(user) {
            return [user?.Security?.General?.UserAdmin !== true, "Cannot view this area"]
        },
        "menuActions": [{
            "label": "Add User",
            "action": addUser
        }]
    }
}, {
    "path": "/Userdata/:id",
    "component": ItemView,
    "name": "Userdata",
    "meta": {
        "app": "Home",
        "name": "Userdata",
        "sideNav": false,
        "appSwitcher": false,
        "dataPath": "Userdata",
        "selectionRoute": "Userdata/{ID}",
        title(item) {
            return `User: ${item.Basic.name}`
        },
        "dataFields": [{
            "label": "Name",
            "key": "Basic.name"
        }, {
            "label": "Email",
            "key": "Basic.email"
        }, {
            "label": "Department",
            "key": "Basic.department"
        }, {
            "label": "Position",
            "key": "Basic.job_title"
        }, {
            "label": "Manager",
            compute({ pageItem, store }) {
                if (!pageItem?.Basic?.ManagerID) return "No manager";
                else return get(store.state.users, `${pageItem.Basic.ManagerID}.name`, "No manager");
            }
        }],
        "menuActions": [{
            "label": "Update User",
            "action": updateUser
        }, {
            "label": "Specify Design Changes",
            "action": setDesignChangeFilter
        }, {
            "label": "Disable User",
            "action": disableUser
        }],
        "attachedItems": {
            "Security": {
                "attachables": security
            }
        }
    }
}, {
    "path": "/Home/CustomerManager",
    "component": { ...ItemListView },
    "name": "Customer Account Manager",
    "meta": {
        "app": "Home",
        "sideNav": true,
        "name": "Customer Account Manager",
        "sideNavSection": "App Admin",
        "sideNavPosition": 2,
        "appSwitcher": false,
        "tableSettings": "CustomerAccounts",
        "dataPath": "customers",
        "selectionRoute": "Home/CustomerManager/{key}",
        lock(user) {
            return [user?.Security?.General?.UserAdmin !== true, "Cannot view this area"]
        },
        "menuActions": [{
            "label": "Add Customer",
            "action": addCustomer
        }]
    },
}, {
    "path": "/Home/CustomerManager/:id",
    "component": { ...ItemView },
    "name": "Customer",
    "meta": {
        "app": "Home",
        "sideNav": false,
        "name": "Customer",
        "appSwitcher": false,
        "dataPathOverride": "customers/:id",
        title(item) {
            return item.Name;
        },
        "dataFields": [{
            "label": "Name",
            "key": "name"
        }, {
            "label": "Email",
            "key": "email"
        }, {
            "label": "Customer",
            "key": "customer"
        }],
        "attachedItems": {},
        "menuActions": []
    },
}, {
    "path": "/Home/Bugs",
    "component": { ...ItemListView },
    "name": "Bugs & Enhancements",
    "meta": {
        "app": "Home",
        "sideNav": true,
        "name": "Bugs & Enhancements",
        "sideNavSection": "App Admin",
        "sideNavPosition": 7,
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Bugs",
        "dataPath": "Home/Bugs",
        "selectionRoute": "Home/Bugs/{ID}",
        filter(item) {
            return item.Status !== "Done";
        },
        "menuActions": [{
            "label": "Add Bug",
            "action": addBug
        }]
    }
}, {
    "path": "/Home/Bugs/:id",
    "component": ItemView,
    "name": "Bug",
    "meta": {
        "app": "Home",
        "name": "Bug",
        "dataPath": "Home/Bugs",
        "selectionRoute": "Home/Bugs/{ID}",
        title(item) {
            return "Bug"
        },
        "dataFields": [{
            "label": "Raised By",
            "key": "User.Name"
        }, {
            "label": "App / Section",
            "key": "Section"
        }, {
            "label": "Status",
            "key": "Status"
        }, {
            "label": "Description",
            "key": "Description",
            "class": "col-span-3",
            "grow": true
        }],
        "attachedItems": {},
        "menuActions": [{ label: "Update Bug", action: editBug }, { label: "Close Bug", action: completeBug }]
    }
    }, ...forms, ...handbooks, ...changeRequests]
